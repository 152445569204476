import React from "react"
import firebase from "gatsby-plugin-firebase"

import Layout from "../components/layout"
import SEO from "../components/seo"

import comingSoonIcon from "../images/image_coming_soon.svg"
import appStoreIcon from "../images/btn_app_store.svg"
import googlePlayIcon from "../images/btn_google_play.svg"
import heartIcon from "../images/image_heart.svg"
import liveChatIcon from "../images/image_live_chat.svg"
import imageBackground from "../images/image_background_with_phone.svg"
import imageBackgroundMobile from "../images/m_image_background_with_phone.svg"
import imageBackgroundMobileCloudLogo from "../images/m_image_cloud_logo.svg"

// Change to true once live
let online = false

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false })
      firebase.analytics().logEvent("active_session_web")
    }, 1500)
  }

  handleImageLoaded = e => {}

  render() {
    const { loading } = this.state

    return (
      <Layout>
        <SEO title="Speesh" description="A Virtual Friendship App" />
        <div
          className={`flex flex-row justify-center ${
            !loading && "root-bg fade-in"
          }`}
        >
          {loading && (
            <div
              className="flex items-center justify-center h-screen w-screen"
              style={{ overflowY: "hidden", overflowX: "hidden" }}
            >
              <div className="cssload-dots">
                <div className="cssload-dot"></div>
                <div className="cssload-dot"></div>
                <div className="cssload-dot"></div>
                <div className="cssload-dot"></div>
                <div className="cssload-dot"></div>
              </div>
            </div>
          )}

          <div
            className={`flex md:flex-row flex-col-reverse max-w-screen-lg ${
              loading && "hidden"
            }`}
          >
            <div className="flex-3 self-center justify-end">
              <div className="flex flex-col md:mb-40 mb-10 text-center md:text-left mx-10 md:mx-0">
                <h1 className="title m-2">VIRTUAL FRIENDSHIP APP</h1>
                <p className="content m-2">
                  Speesh is a platform where users able to interact and make
                  friends with another person through a Live Video Chat!
                  Showcase your talent, real life experience in a healthy,
                  creative and a positive way. Speesh will be coming to iOS and
                  Android so stay tune!
                </p>
                <div className="flex flex-row justify-center md:justify-start">
                  {!online ? (
                    <div className="m-2">
                      <img
                        id="img1"
                        src={comingSoonIcon}
                        alt="Coming Soon!"
                        onLoad={e => this.handleImageLoaded(e)}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="m-2">
                        <img
                          id="img2"
                          onLoad={e => this.handleImageLoaded(e)}
                          src={appStoreIcon}
                          alt="Apple App Store"
                          onClick={() => {
                            alert("Coming Soon")
                          }}
                          className="cursor-pointer"
                        />
                      </div>
                      <div className="m-2">
                        <img
                          id="img3"
                          onLoad={e => this.handleImageLoaded(e)}
                          src={googlePlayIcon}
                          alt="Google Play Store"
                          onClick={() => {
                            alert("Coming Soon")
                          }}
                          className="cursor-pointer"
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="flex-4 right-container">
              <div className="flex md:h-screen main-bg justify-center">
                <div className="flex items-center">
                  <div className="relative md:pl-6 pl-0">
                    <img
                      id="img4"
                      onLoad={e => this.handleImageLoaded(e)}
                      src={imageBackground}
                      alt="background"
                      className="md:pl-8 relative for-web"
                    />

                    <div className="flex flex-col justify-center relative pt-4 for-mobile">
                      <img
                        id="img5"
                        onLoad={e => this.handleImageLoaded(e)}
                        src={imageBackgroundMobileCloudLogo}
                        alt="Speeh logo"
                        className="for-mobile"
                        style={{ minWidth: "100%" }}
                      />
                      <img
                        id="img6"
                        onLoad={e => this.handleImageLoaded(e)}
                        src={imageBackgroundMobile}
                        alt="background"
                        className="relative z-20 for-mobile w-full"
                        style={{ marginTop: "-12%" }}
                      />
                    </div>

                    <img
                      id="img7"
                      onLoad={e => this.handleImageLoaded(e)}
                      src={liveChatIcon}
                      alt="live chat icon"
                      className="absolute vert-move for-web"
                      style={{
                        top: "25%",
                        left: "14%",
                        width: "20%",
                      }}
                    />
                    <img
                      id="img8"
                      onLoad={e => this.handleImageLoaded(e)}
                      src={heartIcon}
                      alt="heart icon"
                      className="absolute vert-move for-web"
                      style={{ top: "44%", right: "10%", width: "13%" }}
                    />

                    <img
                      id="img9"
                      onLoad={e => this.handleImageLoaded(e)}
                      src={liveChatIcon}
                      alt="live chat icon"
                      className="absolute vert-move for-mobile z-20"
                      style={{
                        top: "35%",
                        left: "10%",
                        width: "20%",
                      }}
                    />
                    <img
                      id="img10"
                      onLoad={e => this.handleImageLoaded(e)}
                      src={heartIcon}
                      alt="heart icon"
                      className="absolute vert-move for-mobile z-20"
                      style={{ top: "46%", right: "3%", width: "13%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
